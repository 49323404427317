<style>
.PlanejamentoAcaoFormCampos__Card--treslinhas {
  min-height: 268px;
  height: 100%;
}

.PlanejamentoAcaoFormCampos .title-float {
  font-weight: 400;
  font-size: 20px;
}

.Painel_Planejamento_Acao .v-expansion-panel__header__icon {
  margin-right: 1em;
}

.Painel_Planejamento_Acao .v-expansion-panel__header {
  padding: 0;
  margin-left: 1.5em;
  min-height: 42px;
}

.select--sem--linha > .v-input__control > .v-input__slot:before {
  width: 0% !important;
}

.select--sem--linha > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.tabelaAcoesMassivas .v-data-table__wrapper table {
  width: 100%;
}
</style>
<template>
  <div class="PlanejamentoAcaoFormCampos">
    <v-form ref="formGeral"
            v-model="formGeralValido"
            lazy-validation
            autocomplete="off">

      <v-container fluid grid-list-md class="my-2">
        <v-row>
          <v-col cols="12" md="6" class="pa-0 pr-md-2">
            <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
              <v-container fluid grid-list-md>
                <v-row align="center" justify="center">
                  <v-col cols="12" class="pb-0">
                    <v-autocomplete
                      autocomplete="off"
                      id="planejamento-autocomplete-divisao"
                      class="custom-autocomplete"
                      v-model="acaoMassiva.divisao"
                      return-object
                      :items="divisoesFiltradas"
                      :label="`${$tc('label.divisao', 1)} *`"
                      :no-data-text="$tc('message.nenhum_registro', 1)"
                      item-text="nome"
                      item-value="id"
                      required
                      :disabled="somenteLeitura || desabilitaDivisao || acaoMassiva.idAcaoPrevia!=null"
                      :clearable="true"
                      @input="divisaoAlterada"
                      @click:append="() => triggerSelecao('planejamento-autocomplete-divisao')"
                      @click.native="(i) => buscaAutocomplete(i, buscarDivisoes)">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12" class="py-0">
                    <v-autocomplete
                      autocomplete="off"
                      id="planejamento-autocomplete-unidadenegocio"
                      class="custom-autocomplete"
                      v-model="acaoMassiva.unidadeNegocio"
                      return-object
                      :items="unidadesNegocioDivisao"
                      :label="`${$tc('label.unidade_negocio', 1)} *`"
                      :no-data-text="$tc('message.nenhum_registro', 1)"
                      item-text="nomExtensao"
                      item-value="id"
                      required
                      :disabled="somenteLeitura || desabilitaUnidadeNegocio"
                      :clearable="true"
                      @input="extensaoAlterada"
                      @click:append="() => triggerSelecao('planejamento-autocomplete-unidadenegocio')"
                      @click.native="(i) => buscaAutocomplete(i, buscarUnidadesNegocioDivisao)">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="somenteLeitura || acaoMassiva.idAcaoPrevia!=null">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          :value="formatarData(acaoMassiva.dtaInicio)"
                          :required="campoObrigatorio"
                          :disabled="somenteLeitura || acaoMassiva.idAcaoPrevia!=null"
                          clearable
                          @click:clear="limpaData"
                          :label="`${$t('label.data_inicio')} *`"
                          prepend-icon="event"
                          :readonly="true">
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-br"
                        color="primary"
                        v-model="acaoMassiva.dtaInicio"
                        :disabled="somenteLeitura || acaoMassiva.idAcaoPrevia!=null"
                        :required="campoObrigatorio"
                        :tipo-selecao="'date'"
                        :min="bloqueioPorPerfil()"
                        @input="tipoVerbaSelecionado(acaoMassiva.formularioConta.idTipoVerba)">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <metadados-campo-dinamico-data
                      :metadados="definicaoDtaFim"
                      v-model="acaoMassiva.dtaFim"
                      :somente-leitura="somenteLeitura || acaoMassiva.idAcaoPrevia!=null"
                      :objeto-container="acao"
                      :tipo-selecao="'date'"
                      :is-data-fim="true"
                      @input="lembreteConfirmarPeriodo();tipoVerbaSelecionado(acaoMassiva.formularioConta.idTipoVerba)"/>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-checkbox
                      :label="$tc('label.devolucao', 2)"
                      :input-value="acaoMassiva.devolucoes"
                      v-model="acaoMassiva.devolucoes"
                      :disabled="true">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      class="custom-autocomplete"
                      v-model="acaoMassiva.calculoApuracao.nome"
                      clearable
                      :label="`${$tc('label.calculo_apuracao',1)} *`"
                      :disabled="true">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      id="calculo_bonificacao"
                      class="custom-autocomplete"
                      v-model="acaoMassiva.calculoBonificacao.nome"
                      clearable
                      :label="`${$tc('label.calculo_bonificacao',1)} *`"
                      :disabled="true">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      id="base_calculo"
                      class="custom-autocomplete"
                      v-model="acaoMassiva.baseCalculo.nome"
                      clearable
                      :label="`${$tc('label.base_calculo',2)} *`"
                      :rules="[rules.required]"
                      :disabled="true">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2" v-if="formFoco">
            <planejamento-acao-form-foco-acao
              ref="formFocoAcao"
              :acao="acaoMassiva"
              :acao-edicao="acaoEdicao"
              :nova-acao="novaAcao"
              :somente-leitura="acoesMassivas.length>0"
              :configuracao="configuracao"
              @PlanejamentoAcaoFormFocoAcao__alteradoFoco="alteradoFoco"
            />
          </v-col>
        </v-row>
      </v-container>
      <div class="my-2 title-float accent--text" v-if="!somenteLeitura && habilitaCadastroAcaoInicial()">
        {{ $tc('label.adicionar_acao', 2) }}
      </div>
      <v-card v-if="!somenteLeitura && habilitaCadastroAcaoInicial()">
        <v-container fluid grid-list-md>
          <v-row>
            <v-col cols="12" md="2" class="pa-3">
              <v-autocomplete
                id="tipo-acao"
                class="custom-autocomplete"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.tipo_acao', 1)} *`"
                v-model="acaoMassiva.acao.tipoAcao"
                :items="tiposAcao"
                return-object
                item-text="nome"
                item-value="id"
                :disabled="somenteLeitura"
                :search-input.sync="tipoAcaoInput"
                :rules="[rules.required]" required
                @click.native="buscarTipoAcao"
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="pa-3">
              <v-text-field
                :label="`${$tc('label.descricao_acao', 1)} *`"
                v-model="acaoMassiva.acao.descricao"
                :rules="[rules.required]" required
                :disabled="somenteLeitura">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pa-3">
              <v-autocomplete
                id="tipo-hierarquia"
                name="tipo-hierarquia"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.hierarquia1')} *`"
                v-model="acaoMassiva.acao.hierarquia1"
                :items="hierarquias1"
                return-object
                item-text="juncao"
                item-value="id"
                :disabled="somenteLeitura"
                :search-input.sync="triggerhierarquiaAlterada"
                :rules="[rules.required]" required
                @click.native="buscarHierarquia1"
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col pa-2 cols="12" md="1">
              <v-dialog
                v-model="menu2"
                v-if="formFoco"
                :close-on-content-click="false"
                offset-y
                persistent
                top>
                <template v-slot:activator="{ on }">
                  <v-select
                    v-model="item"
                    :items="itemsR"
                    append-icon
                    class="select--sem--linha"
                    :label="$tc('title.campo_geral', 2)"
                    multiple
                    readonly>
                    <template v-slot:selection="data">
                      <v-icon v-on="on">{{ data.item }}</v-icon>
                    </template>
                  </v-select>
                </template>

                <template>
                  <v-card>
                    <v-card-title>
                      {{ $tc('title.campo_geral', 2) }}
                    </v-card-title>
                    <v-container fluid grid-list-md :key="key">
                      <metadados-container-layout
                        v-if="parametrosAplicados"
                        :metadados-entidade="metadadosCamposGerais"
                        :campos-formulario="camposFormulario"
                        :ordenacao-campos="ordenacaoCampos"
                        :layout-class="layoutClass"
                        :input-layout="inputLayout"
                        :objeto="acaoMassiva.acao.camposGerais"
                        :ignora-obrigatorios="false"
                        :explode-hierarquia="true"
                        :somente-leitura="somenteLeitura"
                        @MetadadosContainerLayout_valorAlterado="valorDinamicoAlterado"
                        ref="container">
                      </metadados-container-layout>
                      <v-container fluid>
                        <v-row>
                          <v-col align="right">
                            <v-btn slot="acoes-padrao" color="error" @click="cancelar('camposGerais')"
                                   class="mr-3">
                              {{ $t('label.fechar') }}
                            </v-btn>
                            <v-btn slot="acoes-padrao" @click="limparCamposGerais()"
                                   class="mr-3">
                              {{ $t('label.limpar') }}
                            </v-btn>
                            <v-btn slot="acoes-padrao" @click="cancelar('camposGerais')"
                                   class="mr-3"
                                   dark
                                   color="primary">
                              {{ $t('label.confirmar') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-container>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2" class="pa-3">
              <v-autocomplete
                class="custom-autocomplete"
                v-model="acaoMassiva.acao.periodosApuracao"
                clearable
                chips
                multiple
                deletable-chips
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :items="periodosApuracaoPermitidos"
                :label="`${$tc('label.periodo_apuracao',1)} *`"
                item-text="nome"
                item-value="valor"
                :rules="[rules.requiredAny]"
                :disabled="somenteLeitura || periodosApuracaoPermitidos.length === 1">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="pa-3">
              <v-autocomplete
                class="custom-autocomplete"
                v-model="acaoMassiva.acao.formaPagamento"
                clearable
                :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                :items="formasBonificacaoPermitidas"
                :label="`${$tc('label.forma_bonificacao',2)} *`"
                item-text="nome"
                item-value="valor"
                :rules="[rules.required]"
                @change="formaBonificacaoSelecionada"
                :disabled="somenteLeitura || formasBonificacaoPermitidas.length === 1">
              </v-autocomplete>
            </v-col>
            <v-col pa-2 cols="12" md="1">
              <v-dialog
                v-model="menu"
                v-if="acaoMassiva.acao.formaPagamento"
                offset-y
                top>
                <template v-slot:activator="{ on }">
                  <v-select
                    v-model="item"
                    :items="itemsR"
                    append-icon
                    class="select--sem--linha"
                    :label="$tc('label.meta', 1)"
                    multiple
                    readonly>
                    <template v-slot:selection="data">
                      <v-icon v-on="on">{{ data.item }}</v-icon>
                    </template>
                  </v-select>
                </template>
                <template>
                      <planejamento-acao-form-apuracao-intervalo
                          :somenteLeitura="somenteLeitura"
                          :calculoVolume="calculoVolume"
                          :destacar-meta="destacarMeta"
                          :sigla="sigla"
                          :verbaPercentual="verbaPercentual"
                          :metas="acaoMassiva.acao.metas"
                          @PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte="alterarCoeficienteCalculoValorTotalAcao"
                          @PlanejamentoAcaoFormApuracaoIntervalo__fecharModal="cancelar"
                          @retirarDestaque="() => destacarMeta = false"
                      />
                </template>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2" class="pa-2" d-flex v-if="tiposPagamentoPermitidos.length">
              <v-autocomplete
                id="tipo_pagamento"
                class="custom-autocomplete"
                v-model="acaoMassiva.acao.tipoPagamento"
                clearable
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :items="tiposPagamentoPermitidos"
                :label="`${$tc('label.tipo_pagamento',2)} *`"
                item-text="nome"
                item-value="valor"
                :rules="[rules.required]"
                :disabled="somenteLeitura || tiposPagamentoPermitidos.length === 1">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="pa-2"
                   v-if="verbaPercentual && (acaoMassiva.cliente || acaoMassiva.holding) && acaoMassiva.clientePagador
                   && acaoMassiva.acao.metas.length>0 && acaoMassiva.dtaInicio && acaoMassiva.dtaFim">
              <input-decimal
                append-icon="replay"
                :label="$tc('label.valor', 1)"
                :hint="valorAcaoCalculadoFormatado"
                persistent-hint
                v-model="acaoMassiva.acao.valor"
                suffix="%"
                :disabled="somenteLeitura"
                type="Number"
                min="0"
                @input="alterarValorAcao"
                @InputDecimal_clickAppend="calcularValorAutomatico"
              ></input-decimal>
            </v-col>
            <v-col cols="12" md="2" class="pa-2"
                   v-if="verbaPercentual && (acaoMassiva.cliente || acaoMassiva.holding) && acaoMassiva.clientePagador
                   && acaoMassiva.acao.metas.length === 0">
              <input-decimal
                :label="$tc('label.valor', 1)"
                v-model="acaoMassiva.acao.valor"
                suffix="%"
                :disabled="somenteLeitura"
                type="Number"
                min="0"
                @input="alterarValorAcao"
              ></input-decimal>
            </v-col>
            <v-col cols="12" md="2" class="pa-2"
                   v-if="verbaFixa && (acaoMassiva.cliente || acaoMassiva.holding) && acaoMassiva.clientePagador
                   && acaoMassiva.dtaInicio && acaoMassiva.dtaFim && acaoMassiva.acao.metas.length>0">
              <input-money
                append-icon="replay"
                :label="$tc('label.valor', 1)"
                :hint="valorAcaoCalculadoFormatado"
                persistent-hint
                v-model="acaoMassiva.acao.valor"
                :disabled="somenteLeitura"
                @input="alterarValorAcao"
                @InputMoney_clickAppend="calcularValorAutomatico"
              ></input-money>
            </v-col>
            <v-col cols="12" md="2" class="pa-2"
                   v-if="verbaFixa && (acaoMassiva.cliente || acaoMassiva.holding) && acaoMassiva.clientePagador
                   && acaoMassiva.acao.metas.length === 0">
              <input-money
                :label="$tc('label.valor', 1)"
                v-model="acaoMassiva.acao.valor"
                :disabled="somenteLeitura"
                @input="alterarValorAcao"
              ></input-money>
            </v-col>
            <v-col cols="12" md="3" class="pa-2" v-if="acaoMassiva.acao.formaPagamento">
              <v-select
                id="planejamento-cc-autocomplete-tipoverba"
                :label="`${$t('label.tipo_verba')} *`"
                :items="tiposVerbasPermitidas"
                item-text="nome"
                item-value="id"
                :append-icon="mostrarIconeVerbaBloqueada()"
                v-model="acaoMassiva.formularioConta.idTipoVerba"
                :disabled="somenteLeitura || !acaoMassiva.dtaInicio || !acaoMassiva.dtaFim"
                @change="tipoVerbaSelecionado(acaoMassiva.formularioConta.idTipoVerba)"
              />
            </v-col>
            <v-col cols="12" md="3" class="pa-2" v-if="acaoMassiva.acao.formaPagamento">
              <v-autocomplete
                id="planejamento-cc-autocomplete-periodo"
                class="custom-autocomplete"
                multiple
                v-model="acaoMassiva.formularioConta.periodos"
                return-object
                item-text="nome"
                item-value="id"
                :items="periodosContas"
                :label="`${$tc('label.periodo', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :clearable="true"
                @change = "periodosSelecionados()"
                :disabled="somenteLeitura || !acaoMassiva.formularioConta.idTipoVerba || !acaoMassiva.dtaInicio || !acaoMassiva.dtaFim"
                :placeholder="(somenteLeitura || !acaoMassiva.formularioConta.idTipoVerba || !acaoMassiva.dtaInicio || !acaoMassiva.dtaFim)
                  ? null
                   : $tc('message.digite_para_pesquisar', 1)">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="text-center" v-if="formFoco && acaoMassiva.formularioConta.idTipoVerba !== null && acaoMassiva.acao.descricao !== null
            && acaoMassiva.formularioConta.periodos.length>0
            && acaoMassiva.acao.hierarquia1 && (acaoMassiva.cliente || acaoMassiva.holding)
            && acaoMassiva.clientePagador && acaoMassiva.acao.valor>0 && carregado && acaoMassiva.acao.formaPagamento">
              <PlanejamentoAcaoMassivaContaCorrente
                ref="formContaCorrente"
                :tipo-acao="acaoMassiva.acao.tipoAcao"
                :configuracao="configuracao"
                :objeto-passo-geral="acaoMassiva"
                :objeto-passo-comportamento="acaoMassiva"
                :acao-edicao="acaoEdicao"
                :nova-acao="novaAcao"
                :somente-leitura="somenteLeitura"
                :edicao="edicao"
                :key="key"
                :get-objeto-outro-passo="getObjetoOutroPasso"
                @PlanejamentoAcaoFormFocoAcao__alteradoFoco="alteradoFoco"
                @PlanejamentoAcaoFormCampos__HabilitaSalvar="exibeSalvar"
                @PlanejamentoAcaoFormCampos__ResetaConta="resetaConta"
              />
            </v-col>
            <confirm
              ref="cancelamentoDialog"
              :persistent="true">
            </confirm>
          </v-row>
        </v-container>
        <v-container fluid>
          <v-row>
            <v-col align="right">
              <v-btn slot="acoes-padrao" @click="limparAcoesMassiva()" class="mr-3">
                {{ $t('label.cancelar') }}
              </v-btn>
              <v-btn slot="acoes-padrao" @click="salvarAcaoMassivaRecarregar()"
                     v-if="habilitarSalvar()"
                     class="mr-3"
                     dark
                     color="primary">
                {{ $t('label.salvar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div class="my-2 title-float accent--text" v-if="habilitaCadastroAcaoInicial()" v-show="this.ehEdicao()">
        {{ $tc('label.acoes', 2) }}
        <v-row justify="end">
          <v-col cols="12" sm="4" md="3">
            <v-text-field
              v-model="search"
              @input="filtrar"
              append-icon="search"
              :label="$t('label.pesquisar')"
              :disabled="somenteLeitura"
              single-line
              clearable
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div :disabled="somenteLeitura" v-if="habilitaCadastroAcaoInicial()" v-show="this.ehEdicao()">
        <v-container fluid grid-list-md>
          <v-row>
            <v-card style="width: 100%;">
              <v-data-table
                class="tabelaAcoesMassivas"
                :headers="headersTabela"
                :items="acoesMassivas"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                itemsPerPageOptions: [10, 25, 50],
              }">
                <template v-slot:item.vlr_acao="{ item }">
                  <span>{{ formatarValorAcao(item.vlr_acao, item.forma_bonificacao) }}</span>
                </template>
                <template v-slot:item.edit="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="editarAcaoMassiva(item)" v-on="on" text icon>
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.editar') }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.copiar="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="copiarAcaoMassiva(item)" v-on="on" text icon>
                        <v-icon>add_to_photos</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.copiar') }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="openConfirmExclusaoAcao(item)" v-on="on" text icon>
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.excluir') }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
        </v-container>
      </div>
      <div class="PlanejamentoAcaoForm_Acoes" v-if="habilitaCadastroAcaoInicial()">
        <planejamento-acao-form-rodape
          :acaoEdicao="acaoMassiva"
          ref="rodapeAcoes"/>
      </div>
    </v-form>
    <confirm ref="confirm" :message="dialog.message" :persistent="true" @agree="dialog.agree"
             @disagree="dialog.disagree"></confirm>
    <confirm
        ref="confirmExclusaoAcao"
        :message="$t('title.confirm_aprov_mass_irrev')"
        :persistent="true"
        @agree="cancelarAcao"></confirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import AcaoConfiguracao from '@/spa/planejamento-acao-massivo/AcaoConfiguracao';
import PlanejamentoAcaoMassivaContaCorrente from '@/spa/planejamento-acao-massivo/PlanejamentoAcaoMassivaContaCorrente';
import PlanejamentoAcaoFormFocoAcao from '@/spa/planejamento-acao-massivo/form/PlanejamentoAcaoFormFocoAcao';
import {
  buscarAcoesInseridas,
  buscarinfoAcaoTradePrevia,
} from '@/common/resources/suzano-planejamento-acao-listagem-massiva';
import PlanejamentoAcaoFormApuracaoIntervalo from './PlanejamentoAcaoFormApuracaoIntervalo';

import MetadadosCampoDinamicoData from '../../../produto/shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import PlanejamentoAcaoFormCamposDefinicao from './PlanejamentoAcaoFormCamposDefinicao';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';
import {
  convertToMoney,
  convertToNumberFormat, convertToPercent, numberBetweenValues,
} from '../../../produto/common/functions/numeric';
import InputMoney from '../../../produto/shared-components/inputs/InputMoney';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import InputDecimal from '../../../produto/shared-components/inputs/InputDecimal';
import {
  obterCalendarioPorMnemonico,
} from '../../../produto/common/resources/planejamento/planejamento-acao-cadastro';
import MetadadosContainerLayout from '../../../../../tradelinks_produto/tradelinks_view_core/src/shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoFormRodape from './PlanejamentoAcaoFormRodape';

export default {
  components: {
    MetadadosCampoDinamicoData,
    PlanejamentoAcaoFormApuracaoIntervalo,
    MetadadosContainerLayout,
    PlanejamentoAcaoFormFocoAcao,
    PlanejamentoAcaoMassivaContaCorrente,
    InputMoney,
    Confirm,
    InputDecimal,
    PlanejamentoAcaoFormRodape,
  },
  mixins: [
    PlanejamentoAcaoFormCamposDefinicao,
  ],
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    somenteLeituraFoco: Boolean,
    tipoAcao: {
      type: Object,
      required: false,
    },
    sigla: {
      type: String,
      default: '',
    },
    getObjetoOutroPasso: {
      type: Function,
    },
    calculoVolume: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tiposVerbasPermitidas: [],
      formFoco: false,
      carregado: true,
      acaoExclusao: {},
      dialog: {
        message: '',
        agree: () => {
        },
        disagree: () => {
        },
      },
      acaoEdicao: {
        type: Object,
        default: () => {
        },
      },
      acoesMassivas: [],
      configuracao: {
        type: Object,
      },
      pagination: {
        rowsPerPage: 10,
      },
      acaoPassosContas: {
        type: Object,
      },
      totalPage: 0,
      lastPagination: {},
      search: null,
      esperar: false,
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      mascaraPercent: false,
      qtdeArquivosAnexados: 0,
      formGeralValido: false,
      tiposAcao: [],
      tipoAcaoInput: null,
      triggerhierarquiaAlterada: null,
      destacarMeta: false,
      key: 0,
      acaoMassiva: {
        acoesInseridas: [],
        idAcaoPrevia: null,
        idTipoAcaoPrevia: null,
        divisao: null,
        unidadeNegocio: null,
        dtaInicio: null,
        dtaFim: null,
        devolucoes: true,
        calculoApuracao:
          {
            valor: 'SELLIN_VALOR',
            nome: 'Sell in Valor',
          },
        calculoBonificacao:
          {
            valor: 'SELLIN',
            nome: 'Sell In',
          },
        baseCalculo:
          {
            valor: 'VALOR_BRUTO',
            nome: 'Valor Bruto',
          },
        acao: {
          idAcao: null,
          tipoPagamento: null,
          valor: 0,
          tipoAcao: null,
          descricao: null,
          hierarquia1: {},
          camposGerais: {},
          periodosApuracao: [],
          formaPagamento: null,
          metas: [],
          indRateioDivisao: false,
          alterouDados: false,
        },
        formularioConta: {
          pendente: 0,
          periodos: [],
          periodosPesquisados: [],
          idTipoVerba: null,
        },
      },
      formaBonificacaoAnterior: null,
      ordenacaoCampos: [
        'hierarquia2',
        'hierarquia3',
        'produto',
        'setoratividade',
        'classe',
        'grupomercadoria',
        'familia',
        'linhaproduto',
      ],
      showModal: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        metaAteMenor: () => this.meta.vlrMetaAte > this.meta.vlrMetaDe
          || this.meta.vlrMetaAte === 0 || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVolume: () => !this.meta.vlrMetaAte
          || parseInt(this.meta.vlrMetaAte, 10) > parseInt(this.meta.vlrMetaDe, 10)
          || this.meta.vlrMetaAte === '0' || this.$t('errors.meta_ate_maior_meta_de'),
        requiredAny: (val) => {
          const msg = this.$t('message.campo_obrigatorio');
          return (val && val.length && val.length > 0) || msg;
        },
      },
      meta: {
        index: -1,
      },
      edicao: false,
      formulario: {
        periodosApuracao: null,
        baseCalculo: null,
        formaBonificacao: null,
        tipoPagamento: null,
        calculoBonificacao: null,
        calculoApuracao: null,
        unidadeMedida: null,
        metas: [],
        descontos: [],
      },
      menu: false,
      menu2: false,
      dialogAlteracao: true,
      item: 'assignment',
      itemsR: ['assignment'],
      listaCalculoApuracao: [
        {
          valor: 'SELLIN_VALOR',
          nome: 'Sell in Valor',
        },
      ],
      listaCalculosBonificacoes: [
        {
          valor: 'SELLIN',
          nome: 'Sell In',
        },
      ],
      listaBaseCalculo: [
        {
          valor: 'VALOR_BRUTO',
          nome: 'Valor Bruto',
        },
      ],
      listaPagamentoPermitidos: [
        {
          valor: 'DEPOSITO_CONTA',
          nome: 'Depósito em conta',
        },
        {
          valor: 'DESCONTO_NOTA',
          nome: 'Abatimento',
        },
      ],
      listaFormaBonificacao: [
        {
          valor: 'FIXO',
          nome: this.$tc('label.fixo'),
        },
        {
          valor: 'PERCENTUAL',
          nome: this.$tc('label.percentual'),
        },
      ],
      listaPeriodosApuracao: [
        {
          valor: 'ANUAL',
          nome: this.$tc('label.anual'),
        },
        {
          valor: 'TRIMESTRAL',
          nome: this.$tc('label.trimestral'),
        },
        {
          valor: 'MENSAL',
          nome: this.$tc('label.mensal'),
        },
        {
          valor: 'UNICA_VEZ',
          nome: this.$tc('label.unica_vez'),
        },
      ],
      tiposVerbas: [],
      periodosContas: [],
      verbaSelecionada: {},
      eventoCalendario: {},
      mostrarFraseVerbaBloqueada: false,
      metadadosCamposGerais: null,
      metadadosCampohierarquia1: null,
      extensaoResources: this.$api.extensao(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', []),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    metaIntervalo() {
      return this.configuracao
        && this.configuracao.meta === 'INTERVALO';
    },
    isDetalheAcaoTemplate() {
      return this.configuracao.configuracao.passo3.template === 'DETALHE_ACAO';
    },
    isDetalheCalculoHistorico() {
      const { configuracao } = this.configuracao;
      return configuracao.passo3.template === 'CALCULO_HISTORICO';
    },
    isAcaoVigenciaTrimestral() {
      return this.configuracao.indVigenciaTrimestral;
    },
    calculosApuracoesPermitidos() {
      return this.listaCalculoApuracao;
    },
    calculosBonificacoesPermitidos() {
      return this.listaCalculosBonificacoes;
    },
    basesCalculoPermitidas() {
      return this.listaBaseCalculo;
    },
    tiposPagamentoPermitidos() {
      return this.listaPagamentoPermitidos;
    },
    formasBonificacaoPermitidas() {
      return this.listaFormaBonificacao;
    },
    periodosApuracaoPermitidos() {
      return this.listaPeriodosApuracao;
    },
    verbaPercentual() {
      if (this.acaoMassiva.acao.formaPagamento
        && this.acaoMassiva.acao.formaPagamento === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
    verbaFixa() {
      if (this.acaoMassiva.acao.formaPagamento
        && this.acaoMassiva.acao.formaPagamento === 'FIXO') {
        return true;
      }
      return false;
    },
    valorAcaoCalculadoFormatado() {
      if (this.formulario.valorAcaoCalculado) {
        if (!this.verbaPercentual) {
          return this.convertToMoney(this.formulario.valorAcaoCalculado);
        }
        return this.convertToPercent(this.formulario.valorAcaoCalculado);
      }
      if (!this.verbaPercentual) {
        return this.convertToMoney(0);
      }
      return this.convertToPercent(0);
    },
    headersTabela() {
      const headersTabela = [];
      headersTabela.push({
        text: this.$tc('label.tipo_acao', 1), value: 'tipo_acao', sortable: false,
      });
      headersTabela.push({
        text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false,
      });
      headersTabela.push({
        text: this.$tc('label.tipo_verba', 1), value: 'tipo_verba', sortable: false,
      });
      headersTabela.push({
        text: this.$tc('label.valor_acao', 1), value: 'vlr_acao', sortable: false,
      });
      headersTabela.push({
        text: this.$tc('label.hierarquia1', 1), value: 'hierarquia1', sortable: false,
      });
      headersTabela.push({
        text: ' ', width: '2%', value: 'edit', sortable: false,
      });
      headersTabela.push({
        text: ' ', width: '2%', value: 'copiar', sortable: false,
      });
      headersTabela.push({
        text: ' ', width: '2%', value: 'delete', sortable: false,
      });
      return headersTabela;
    },
  },
  watch: {
    tipoAcaoInput(val) {
      if (val) {
        const idTipoAcao = this.acaoMassiva.acao.tipoAcao.id;
        Promise.all([
          this.configuracaoResource.buscarConfigVigente({ idTipoAcao }),
        ])
          .then((resp) => {
            this.configuracao = new AcaoConfiguracao(resp[0].data);
            this.aplicaParametrizacoes();
            this.preencherValoresAutomaticos();
            this.carregarTiposVerbas();
            this.formFoco = true;
            this.emitirFocoAlterado();
          });
      } else if (this.configuracao.configuracao) {
        this.$refs.formFocoAcao.desabilitaFocoAcaoMassiva();
        this.emitirFocoAlterado();
      } else {
        this.configuracao = null;
        this.formFoco = false;
        this.emitirFocoAlterado();
      }
    },
    triggerhierarquiaAlterada(val) {
      if (val === undefined || val === null) {
        this.limparCamposGerais();
      } else {
        this.acaoMassiva.acao.camposGerais.hierarquia1 = this.acaoMassiva.acao.hierarquia1;
        this.acaoMassiva.acao.hierarquia1 = this.acaoMassiva.acao.camposGerais.hierarquia1;
        this.buscarHierarquia1(this.acaoMassiva.acao.camposGerais.hierarquia1 ? this.acaoMassiva.acao.camposGerais.hierarquia1.idExterno : val);
      }
    },
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (this.filtros === null) {
          return;
        }
        this.filtrarAcoes();
      },
      deep: true,
    },
  },
  methods: {
    lembreteConfirmarPeriodo() {
      alert(this.$t('message.lembrete_confirmacao_periodo'));
    },
    formatarValorAcao(valor, formaBonificacao) {
      if (formaBonificacao !== 'PERCENTUAL') {
        return this.convertToMoney(valor);
      }
      return this.convertToPercent(valor);
    },
    alterarCoeficienteCalculoValorTotalAcao(valor) {
      if (this.valorAcaoTemplateValorTotalAcao) {
        this.formulario.coeficienteValorTotal = valor;
        this.calcularValorTotal();
      } else if (this.ehCalculoAutomatico) {
        this.$emit('PlanejamentoAcaoFormApuracao__alterarValorAcao', valor, 0);
      }
    },
    exibeSalvar() {
      setTimeout(() => {
        const nomeAcao = this.acaoMassiva.acao.descricao;
        this.acaoMassiva.acao.descricao = null;
        this.acaoMassiva.acao.descricao = nomeAcao;
      });
    },
    resetaConta() {
      setTimeout(() => {
        this.key += 1;
        this.edicao = false;
      });
    },
    editarAcaoMassiva(acaoEdit) {
      this.carregado = false;
      this.planejamentoAcaoListagemResource.buscarAcao({ idAcao: acaoEdit.id })
        .then((res) => {
          this.edicao = true;
          this.carregado = true;
          const { passoGeral } = res.data;
          this.acaoMassiva.acao.idAcao = res.data.id;
          this.acaoMassiva.acao.descricao = passoGeral.descricao;
          this.hierarquias1 = [passoGeral.mapaExtensoes.hierarquia1];
          this.hierarquias1.forEach((h) => {
            [h][0].juncao = `${[h][0].idExterno} - ${[h][0].nomExtensao}`;
          });
          this.acaoMassiva.acao.hierarquia1 = passoGeral.mapaExtensoes.hierarquia1;
          this.acaoMassiva.acao.camposGerais = passoGeral.mapaExtensoes;
          this.acaoMassiva.acao.tipoAcao = {
            id: passoGeral.idAcaoTipo,
            indAtivo: true,
            nome: passoGeral.nomeTipoAcao,
          };
          this.tiposAcao = [this.acaoMassiva.acao.tipoAcao];
          const idTipoAcao = passoGeral.idAcaoTipo;
          const { passoComportamento } = res.data;
          this.acaoMassiva.acao.periodosApuracao = passoComportamento.periodosApuracao;
          this.acaoMassiva.acao.formaPagamento = passoComportamento.formaBonificacao;
          this.acaoMassiva.acao.metas = passoComportamento.metas;
          this.acaoMassiva.acao.tipoPagamento = passoComportamento.tipoPagamento;
          this.acaoMassiva.acao.valor = passoComportamento.valorBonificacao;
          Promise.all([
            this.configuracaoResource.buscarConfigVigente({ idTipoAcao }),
          ])
            .then((resp) => {
              this.configuracao = new AcaoConfiguracao(resp[0].data);
              this.aplicaParametrizacoes();
              this.preencherValoresAutomaticos();
              this.carregarTiposVerbas();
            });
          const { passoContas } = res.data;
          this.acaoPassosContas = passoContas;
          const rateioDivisao = this.tiposVerbasPermitidas.filter((verba) => verba.id === passoContas.tipoVerba.id)
            .map((verbaSelecionada) => verbaSelecionada.indRateioDivisao)[0];
          this.acaoMassiva.acao.indRateioDivisao = rateioDivisao;
          if (rateioDivisao) {
            this.acaoMassiva.acao.vlrMonetario = passoGeral.vlrMonetario;
            this.acaoEdicao.passoGeral.vlrMonetario = passoGeral.vlrMonetario;
            this.acaoMassiva.acao.idCalculoMonetario = passoGeral.idCalculoMonetario;
            this.acaoEdicao.passoGeral.idCalculoMonetario = passoGeral.idCalculoMonetario;
          }
          const listTiposVerbas = [];
          listTiposVerbas.push(passoContas.tipoVerba);
          this.tiposVerbas = listTiposVerbas;
          this.acaoMassiva.formularioConta.idTipoVerba = passoContas.tipoVerba.id;
          const listPeriodos = [];
          passoContas.periodos.forEach((p) => {
            listPeriodos.push(p);
          });
          this.acaoEdicao.passoContas = passoContas;
          this.periodosContas = listPeriodos;
          this.acaoMassiva.formularioConta.periodos = this.periodosContas;
          this.valorDinamicoAlterado();
        })
        .catch((err) => {
          this.carregado = true;
          this.$toast(err.data.error);
        });
    },
    copiarAcaoMassiva(acao) {
      this.carregado = false;
      this.planejamentoAcaoListagemResource.buscarAcao({ idAcao: acao.id })
        .then((res) => {
          this.edicao = false;
          this.carregado = true;
          const { passoGeral } = res.data;
          this.acaoMassiva.acao.idAcao = null;
          this.acaoMassiva.acao.descricao = passoGeral.descricao;
          this.hierarquias1 = [passoGeral.mapaExtensoes.hierarquia1];
          this.hierarquias1.forEach((h) => {
            [h][0].juncao = `${[h][0].idExterno} - ${[h][0].nomExtensao}`;
          });
          this.acaoMassiva.acao.hierarquia1 = passoGeral.mapaExtensoes.hierarquia1;
          this.acaoMassiva.acao.camposGerais = passoGeral.mapaExtensoes;
          this.acaoMassiva.acao.tipoAcao = {
            id: passoGeral.idAcaoTipo,
            indAtivo: true,
            nome: passoGeral.nomeTipoAcao,
          };
          this.tiposAcao = [this.acaoMassiva.acao.tipoAcao];
          const idTipoAcao = passoGeral.idAcaoTipo;
          Promise.all([
            this.configuracaoResource.buscarConfigVigente({ idTipoAcao }),
          ])
            .then((resp) => {
              this.configuracao = new AcaoConfiguracao(resp[0].data);
              this.aplicaParametrizacoes();
              this.preencherValoresAutomaticos();
              this.carregarTiposVerbas();
            });
          const { passoContas } = res.data;
          this.acaoPassosContas = passoContas;
          const rateioDivisao = this.tiposVerbasPermitidas.filter((verba) => verba.id === passoContas.tipoVerba.id)
            .map((verbaSelecionada) => verbaSelecionada.indRateioDivisao)[0];
          this.acaoMassiva.acao.indRateioDivisao = rateioDivisao;
          const listTiposVerbas = [];
          listTiposVerbas.push(passoContas.tipoVerba);
          this.tiposVerbas = listTiposVerbas;
          this.acaoMassiva.formularioConta.idTipoVerba = passoContas.tipoVerba.id;
          this.acaoEdicao.passoContas = passoContas;
          const listPeriodos = [];
          passoContas.periodos.forEach((p) => {
            listPeriodos.push(p);
          });
          this.periodosContas = listPeriodos;
          this.acaoMassiva.formularioConta.periodos = this.periodosContas;
          const { passoComportamento } = res.data;
          this.acaoMassiva.acao.periodosApuracao = passoComportamento.periodosApuracao;
          this.acaoMassiva.acao.formaPagamento = passoComportamento.formaBonificacao;
          this.acaoMassiva.acao.metas = passoComportamento.metas;
          this.acaoMassiva.acao.metas.forEach((p) => {
            p.id = null;
          });
          this.acaoMassiva.acao.tipoPagamento = passoComportamento.tipoPagamento;
          this.acaoMassiva.acao.valor = passoComportamento.valorBonificacao;
          this.valorDinamicoAlterado();
          this.$toast(this.$t('message.distribuir_valores_conta_corrente'));
        })
        .catch((err) => {
          this.carregado = true;
          this.$toast(err.data.error);
        });
    },
    cancelarAcao() {
      const param = { idAcao: this.acaoExclusao.id };
      this.planejamentoAcaoResource.cancelarAcao(param, param)
        .then(async () => {
          await this.filtrarAcoes();
          this.$toast(this.$t('message.acao_massiva_excluida'));
          if (this.acoesMassivas.length === 0) {
            this.voltarRotaPrincipal();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    limparAcoesMassiva() {
      this.edicao = false;
      this.acaoMassiva.acao.idAcao = null;
      this.acaoMassiva.acao.descricao = null;
      this.limparCamposGerais();
      this.acaoMassiva.acao.alterouDados = false;
      this.acaoMassiva.acao.hierarquia1 = null;
      this.acaoMassiva.acao.tipoAcao = null;
      this.acaoMassiva.acao.periodosApuracao = [];
      this.acaoMassiva.acao.formaPagamento = null;
      this.acaoMassiva.acao.tipoPagamento = null;
      this.acaoMassiva.formularioConta.idTipoVerba = null;
      this.acaoMassiva.formularioConta.periodos = null;
      this.acaoMassiva.acao.valor = 0;
      this.acaoMassiva.acao.metas = [];
      this.key += 1;
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    async filtrarAcoes() {
      this.acaoMassiva.acoesInseridas = [];
      const filtro = {
        id_acao_previa: this.acaoMassiva.idAcaoPrevia,
        filtro: this.search,
      };

      this.filtros = filtro;

      const parametros = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };
      if (this.acaoMassiva.idAcaoPrevia !== null && this.acaoMassiva.idAcaoPrevia !== undefined) {
        await buscarAcoesInseridas(parametros, this.$resource).then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.acoesMassivas = res.data.resposta;
          res.data.resposta.forEach((r) => {
            this.acaoMassiva.acoesInseridas.push(r.id_acao);
          });
        });
      }
    },
    validaAntesDeSalvar(isSolicitandoAprovacao) {
      return this.validaComponentePassoSalvar(isSolicitandoAprovacao);
    },
    validaContaCorrente() {
      if (this.$refs.formContaCorrente !== undefined) {
        if (this.$refs.formContaCorrente.$refs.componenteContaCorrente !== undefined) {
          return (this.$refs.formContaCorrente.$refs.componenteContaCorrente.existeReserva());
        }
      }
      return false;
    },
    habilitaCadastroAcaoInicial() {
      if (this.edicao === false) {
        if (this.acaoMassiva.dtaInicio !== null
        && this.acaoMassiva.dtaFim !== null
        && (this.acaoMassiva.cliente || this.acaoMassiva.holding)
        && this.acaoMassiva.clientePagador) {
          return true;
        }
        this.acaoMassiva.acao.hierarquia1 = null;
        this.acaoMassiva.acao.tipoAcao = null;
        this.acaoMassiva.acao.periodosApuracao = [];
        this.acaoMassiva.acao.formaPagamento = null;
        this.acaoMassiva.acao.tipoPagamento = null;
        this.acaoMassiva.formularioConta.idTipoVerba = null;
        this.acaoMassiva.formularioConta.periodos = null;
        this.acaoMassiva.acao.valor = 0;
        this.acaoMassiva.acao.metas = [];
        this.acaoMassiva.acao.descricao = null;
        this.acaoMassiva.acao.camposGerais.hierarquia2 = undefined;
        this.acaoMassiva.acao.camposGerais.hierarquia3 = undefined;
        this.acaoMassiva.acao.camposGerais.produto = undefined;
        this.acaoMassiva.acao.camposGerais.setoratividade = undefined;
        this.acaoMassiva.acao.camposGerais.grupomercadoria = undefined;
        this.acaoMassiva.acao.camposGerais.familia = undefined;
        this.acaoMassiva.acao.camposGerais.linhaproduto = undefined;
        this.acaoMassiva.acao.camposGerais.classe = undefined;
        return false;
      }
      return true;
    },
    habilitarSalvar() {
      if (this.edicao === false) {
        if (this.acaoMassiva.acao.descricao !== null
          && this.acaoMassiva.acao.descricao !== ''
          && this.acaoMassiva.acao.camposGerais !== null
          && this.acaoMassiva.acao.hierarquia1 !== null
          && this.acaoMassiva.acao.tipoAcao !== null
          && (this.acaoMassiva.acao.periodosApuracao !== null && this.acaoMassiva.acao.periodosApuracao.length > 0)
          && this.acaoMassiva.dtaInicio !== null
          && this.acaoMassiva.dtaFim !== null
          && this.acaoMassiva.formularioConta.periodos !== null
          && this.acaoMassiva.acao.formaPagamento !== null
          && (this.acaoMassiva.acao.tipoPagamento !== null && this.acaoMassiva.acao.tipoPagamento !== undefined)
          && this.acaoMassiva.formularioConta.idTipoVerba !== null
          && this.acaoMassiva.formularioConta.periodos.length > 0
          && this.acaoMassiva.acao.valor !== 0
          && this.acaoMassiva.acao.metas !== null
          && this.validaContaCorrente()) {
          return true;
        }
      } else if (this.edicao === true) {
        if (this.acaoMassiva.acao.descricao !== null
          && this.acaoMassiva.acao.descricao !== ''
          && this.acaoMassiva.acao.camposGerais !== null
          && this.acaoMassiva.acao.hierarquia1 !== null
          && this.acaoMassiva.acao.tipoAcao !== null
          && (this.acaoMassiva.acao.periodosApuracao !== null && this.acaoMassiva.acao.periodosApuracao.length > 0)
          && this.acaoMassiva.dtaInicio !== null
          && this.acaoMassiva.dtaFim !== null
          && this.acaoMassiva.formularioConta.periodos !== null
          && this.acaoMassiva.acao.formaPagamento !== null
          && (this.acaoMassiva.acao.tipoPagamento !== null && this.acaoMassiva.acao.tipoPagamento !== undefined)
          && this.acaoMassiva.formularioConta.idTipoVerba !== null
          && this.acaoMassiva.formularioConta.periodos.length > 0
          && this.acaoMassiva.acao.valor !== 0
          && this.acaoMassiva.acao.metas !== null) {
          return true;
        }
      }
      return false;
    },
    salvarAcaoMassivaRecarregar() {
      const dataInicial = moment(this.acaoMassiva.dtaInicio);
      const dataFinal = moment(this.acaoMassiva.dtaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return;
      }
      this.salvarAcao()
        .then((idAcao) => {
          if (this.callbackAcao) {
            this.callbackAcao(idAcao);
          }
          this.alternaRota(idAcao);
        })
        .catch((err) => {
          if (err) {
            this.$toast(err.data.error);
          }
        });
    },
    salvarAcao() {
      const acao = this.formataAcaoParaEnvio();

      if (!this.edicao) {
        return this.salvarNovo(acao);
      }
      return this.atualizar(acao);
    },
    formataAcaoParaEnvio() {
      const acao = {
        id: this.acaoMassiva.acao.idAcao,
      };
      this.preencherObjetoFormatado(acao);
      return acao;
    },
    salvarNovo(acao) {
      return this.planejamentoAcaoResource.salvarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          const idAcaoPrevia = res.data;
          if (this.acaoMassiva.idAcaoPrevia === null || this.acaoMassiva.idAcaoPrevia === undefined) {
            this.acaoMassiva.idAcaoPrevia = idAcaoPrevia;
          }
          this.filtrarAcoes();
          this.limparAcoesMassiva();
        });
    },
    atualizar(acao) {
      return this.planejamentoAcaoResource.atualizarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.acao_atualizada'));
          const idAcao = res.data;
          this.idAcao = idAcao;
          this.filtrarAcoes();
          this.limparAcoesMassiva();
        });
    },
    voltarRotaPrincipal() {
      this.$router.push({ name: 'novaAcaoMassiva' });
    },
    alternaRota(idAcao) {
      if (idAcao === null || idAcao === undefined) {
        idAcao = this.acaoMassiva.idAcaoPrevia;
      }
      const routeEdicao = 'editarAcaoMassiva';
      const { name } = this.$router.currentRoute;
      if (routeEdicao !== name) {
        this.$router.push({ name: routeEdicao, params: { idAcao, from: 'planejamento-acao-massivo' } });
      }
    },
    ehEdicao() {
      const routeEdicao = 'editarAcaoMassiva';
      const { name } = this.$router.currentRoute;
      if (routeEdicao !== name) {
        return false;
      }
      return true;
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.esperar = false;
        this.filtrarAcoes();
      }, 5E2);
    },
    limparCamposGerais() {
      this.acaoMassiva.acao.camposGerais.familia = {};
      this.acaoMassiva.acao.camposGerais.grupomercadoria = {};
      this.acaoMassiva.acao.camposGerais.hierarquia2 = {};
      this.acaoMassiva.acao.camposGerais.hierarquia3 = {};
      this.acaoMassiva.acao.camposGerais.linhaproduto = {};
      this.acaoMassiva.acao.camposGerais.produto = null;
      this.acaoMassiva.acao.camposGerais.setoratividade = {};
      this.acaoMassiva.acao.camposGerais.classe = {};
      this.acaoMassiva.acao.camposGerais.hierarquia2 = undefined;
      this.acaoMassiva.acao.camposGerais.hierarquia3 = undefined;
      this.acaoMassiva.acao.camposGerais.produto = undefined;
      this.acaoMassiva.acao.camposGerais.setoratividade = undefined;
      this.acaoMassiva.acao.camposGerais.grupomercadoria = undefined;
      this.acaoMassiva.acao.camposGerais.familia = undefined;
      this.acaoMassiva.acao.camposGerais.linhaproduto = undefined;
      this.acaoMassiva.acao.camposGerais.classe = undefined;
      this.valorDinamicoAlterado();
      this.key += 1;
    },
    cancelar(menu) {
      if (menu === 'camposGerais') {
        this.menu2 = false;
      } else {
        this.menu = false;
      }
    },
    preencherObjetoFormatado(acao) {
      acao.passoGeral = this.getObjetoFormatadoPassoGeral();
      acao.passoComportamento = this.getObjetoFormatadoPassoComportamento();
      if (this.$refs.formContaCorrente.$refs.componenteContaCorrente === undefined) {
        acao.passoContas = {
          reservas: this.acaoPassosContas.reservas,
          idsPeriodos: this.acaoPassosContas.periodos.map((p) => p.id),
          workspace: {
            filtrosUtilizados: this.acaoPassosContas.filtrosUtilizados,
            filtrosAbertos: true,
          },
          idTipoVerba: this.acaoMassiva.formularioConta.idTipoVerba,
        };
      } else {
        acao.passoContas = {
          ...this.$refs.formContaCorrente.$refs.componenteContaCorrente.getObjetoFormatado(),
          idTipoVerba: this.acaoMassiva.formularioConta.idTipoVerba,
        };
      }
    },
    getObjetoFormatadoPassoGeral() {
      const { idConfiguracao } = this.configuracao;
      const {
        id,
        descricao,
        valor,
      } = this.acaoMassiva.acao;

      const {
        divisao,
        unidadeNegocio,
      } = this.acaoMassiva;

      const objetoFormatado = {
        id,
        idAcaoTipo: this.acaoMassiva.acao.tipoAcao.id,
        idConfiguracao,
        descricao,
        idDivisao: divisao.id,
        idUnidadeNegocio: unidadeNegocio.id,
      };

      if (this.configuracao.valorAcaoCalculadoAutomaticamente && this.isDetalheCalculoHistorico) {
        objetoFormatado.valor = valor;
      }
      objetoFormatado.idAcaoTradePrevia = this.acaoMassiva.idAcaoPrevia;
      objetoFormatado.acaoTradePrevia = true;
      this.formataVigenciaAberta(objetoFormatado);
      this.formataExtensoes(objetoFormatado);
      this.formataCamposDinamicos(objetoFormatado);
      this.formataFocoAcao(objetoFormatado);
      if (this.isDetalheAcaoTemplate) {
        objetoFormatado.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
      return objetoFormatado;
    },
    getObjetoFormatadoPassoComportamento() {
      const objetoFormatadoComportamento = {};
      objetoFormatadoComportamento.periodosApuracao = [];
      if (this.acaoMassiva.baseCalculo) {
        objetoFormatadoComportamento.baseCalculo = this.acaoMassiva.baseCalculo.valor;
      }
      if (this.acaoMassiva.acao.tipoPagamento) {
        objetoFormatadoComportamento.tipoPagamento = this.acaoMassiva.acao.tipoPagamento;
      }
      if (this.acaoMassiva.acao.formaPagamento) {
        objetoFormatadoComportamento.formaBonificacao = this.acaoMassiva.acao.formaPagamento;
      }
      if (this.acaoMassiva.calculoBonificacao) {
        objetoFormatadoComportamento.calculoBonificacao = this.acaoMassiva.calculoBonificacao.valor;
      }
      if (this.acaoMassiva.calculoApuracao) {
        objetoFormatadoComportamento.calculoApuracao = this.acaoMassiva.calculoApuracao.valor;
      }
      objetoFormatadoComportamento.meta = 0;

      if (this.acaoMassiva.acao.valor) {
        objetoFormatadoComportamento.valorBonificacao = this.acaoMassiva.acao.valor;
      }
      if (this.acaoMassiva.acao.periodosApuracao) {
        this.acaoMassiva.acao.periodosApuracao.forEach((e) => {
          objetoFormatadoComportamento.periodosApuracao.push(e);
        });
      }
      objetoFormatadoComportamento.descontos = ['DEVOLUCOES'];
      if (this.acaoMassiva.acao.metas) {
        objetoFormatadoComportamento.metas = this.acaoMassiva.acao.metas;
      }
      return objetoFormatadoComportamento;
    },
    getObjetoFormatadoPassoContas() {
    },
    buscarTipoAcao() {
      Promise.all([
        this.tipoAcaoResource.buscarAtivos(),
      ])
        .then((resp) => {
          this.tiposAcao = resp[0].data.filter((t) => t.id !== this.id);
          this.habilitaFoco();
        });
    },
    habilitaFoco() {
      const idTipoAcao = this.tiposAcao[0].id;
      Promise.all([
        this.configuracaoResource.buscarConfigVigente({ idTipoAcao }),
      ])
        .then((resp) => {
          this.configuracao = new AcaoConfiguracao(resp[0].data);
          this.formFoco = true;
          this.aplicaParametrizacoes();
          this.preencherValoresAutomaticos();
          this.carregarTiposVerbas();
          this.emitirFocoAlterado();
        });
    },
    buscarHierarquia1(val) {
      this.param = {};
      if (val) {
        this.param = {
          idNivelExtensao: 8, indiceRecursao: 2, nomExtensao: val,
        };
      } else {
        this.param = {
          idNivelExtensao: 8, indiceRecursao: 2,
        };
      }
      this.acaoMassiva.acao.camposGerais.hierarquia1filtro = undefined;
      return this.extensaoResources.listarAtivos(this.param)
        .then((response) => {
          this.hierarquias1 = response.data.resposta;
          this.hierarquias1.forEach((h) => {
            [h][0].juncao = `${[h][0].idExterno} - ${[h][0].nomExtensao}`;
          });
        });
    },
    headers() {
      return [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'),
          value: 'bonificacao_pagamento',
          sortable: false,
          align: 'center',
        },
      ];
    },
    finalizarSelecaoFormaBonificacao(destacarMeta) {
      this.formaBonificacaoAnterior = this.acaoMassiva.acao.formaPagamento;
      this.destacarMeta = destacarMeta;
    },
    retornarAFormaBonificacaoAnterior() {
      this.formulario.formaBonificacao = this.formaBonificacaoAnterior;
    },
    formaBonificacaoSelecionada() {
      if (this.acaoMassiva.acao.formaPagamento === 'FIXO') {
        this.tiposVerbasPermitidas = this.tiposVerbas.filter((el) => el.indRateioDivisao || !el.indVerbaPercentual);
      }
      if (this.acaoMassiva.acao.formaPagamento === 'PERCENTUAL') {
        this.tiposVerbasPermitidas = this.tiposVerbas.filter((el) => el.indRateioDivisao || el.indVerbaPercentual);
      }
      if (!this.acaoMassiva.acao.metas
        || !this.acaoMassiva.acao.metas.length) {
        this.finalizarSelecaoFormaBonificacao(false);
      } else {
        this.dialog.message = this.$tc('message.alterar_forma_bonificacao_redefinir_meta', 2);
        this.dialog.agree = () => this.finalizarSelecaoFormaBonificacao(true);
        this.dialog.disagree = this.retornarAFormaBonificacaoAnterior;
        this.$refs.confirm.open();
      }
      this.key += 1;
    },
    open() {
      this.titleDialog = this.title;
      if (!this.title || !this.title.length) {
        this.titleDialog = this.$t('title.atencao');
      }
      this.dialog = true;
    },
    novo() {
      this.retirarDestaque();
      this.edicao = false;
      this.meta = {
        index: -1,
      };
      this.showModal = true;
    },
    retirarDestaque() {
      if (this.destacarMeta) {
        this.$emit('retirarDestaque');
      }
    },
    openConfirmRemocao(item) {
      this.retirarDestaque();
      this.meta = item;
      this.$refs.confirmRemocao.open();
    },
    openConfirmExclusaoAcao(item) {
      this.acaoExclusao = item;
      this.$refs.confirmExclusaoAcao.open();
    },
    editar(item) {
      this.retirarDestaque();
      this.edicao = true;
      this.meta = item;
      this.meta.index = this.acaoMassiva.acao.metas.indexOf(item);
      this.showModal = true;
    },
    remover() {
      this.acaoMassiva.acao.metas.splice(this.acaoMassiva.acao.metas.indexOf(this.meta), 1);
    },
    onEvent(evento) {
      if (evento.ehEvento('alterarValorAcao')) {
        this.valorAcaoAlteradoOutroPasso(evento);
      }
    },
    exibirMeta(valor) {
      if (this.calculoVolume) {
        return convertToNumberFormat(valor);
      }
      return convertToMoney(valor);
    },
    emitirCampoProdutoAlterado() {
      const evento = new AcaoComponenteEvento('campoProdutoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    emitirFocoAlterado() {
      const evento = new AcaoComponenteEvento('focoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    getObjetoPasso() {
      return this.getObjetoNatural();
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      if (!this.validacaoCustomizada(isSolicitandoAprovacao)) {
        return false;
      }
      if (this.isDetalheAcaoTemplate) {
        if (!this.validaSecaoDetalheAcao()) {
          return false;
        }
      }
      return true;
    },
    valida() {
      const resultadoFormGeral = this.$refs.formGeral.validate();

      if (!resultadoFormGeral) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }
      if (this.visualizaSecaoRoi
        && this.isDetalheAcaoTemplate) {
        const isAcaoSalvar = false;
        return resultadoFormGeral
          && this.validaSecaoDetalheAcao()
          && this.validaSecaoRoi(isAcaoSalvar)
          && this.validarDatas();
      }
      if (this.visualizaSecaoRoi) {
        const isAcaoSalvar = false;
        return resultadoFormGeral
          && this.validaSecaoRoi(isAcaoSalvar)
          && this.validarDatas();
      }
      if (this.isDetalheAcaoTemplate) {
        return resultadoFormGeral
          && this.validaSecaoDetalheAcao()
          && this.validarDatas();
      }

      return resultadoFormGeral
        && this.validarDatas();
    },
    alteraValorAcao(val) {
      this.acaoMassiva.acao.valor = val;
      this.$emit('PlanejamentoAcaoForm__CampoValorAlterado');
    },
    esconderEntidade(entidade) {
      if (entidade && entidade.desAtributos) {
        const json = JSON.parse(entidade.desAtributos.value) || {};
        return !!(json && json.esconderEntidade);
      }
      return false;
    },
    extensaoAlterada() {
      this.emitirCampoProdutoAlterado();
      if (this.$refs.formFocoAcao) {
        this.$refs.formFocoAcao.limpaFocoAcao();
      }
    },
    valorDinamicoAlterado() {
      const hier2 = this.acaoMassiva.acao.camposGerais.hierarquia2;
      this.acaoMassiva.acao.camposGerais.hierarquia2 = hier2;
    },
    valorAcaoAlteradoOutroPasso(evento) {
      this.acaoMassiva.acao.valor = evento.objeto.valor;
      this.acaoMassiva.valorCalculado = evento.objeto.valorCalculado;

      this.$emit('PlanejamentoAcaoForm__evento', new AcaoComponenteEvento('campoValorAlterado'));
    },
    getObjetoNatural() {
      const objeto = { ...this.acao, ...this.getFocoOuOrigem() };
      if (this.isDetalheAcaoTemplate) {
        objeto.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
      return objeto;
    },
    getFocoOuOrigem() {
      const objeto = {};

      objeto.focoAcao = this.$refs.formFocoAcao.acao.clientePagador;
      return objeto;
    },
    formataVigenciaAberta(objetoFormatado) {
      if (this.isAcaoVigenciaTrimestral) {
        objetoFormatado.dtaInicio = moment(this.acaoMassiva.dtaInicio, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        objetoFormatado.dtaFim = moment(this.acaoMassiva.dtaFim, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else {
        objetoFormatado.dtaInicio = this.acaoMassiva.dtaInicio;
        objetoFormatado.dtaFim = this.acaoMassiva.dtaFim;
      }
    },
    formataExtensoes(objetoFormatado) {
      objetoFormatado.mapaExtensoes = {};
      objetoFormatado.idProduto = [];
      if (this.acaoMassiva.acao.camposGerais.hierarquia1) {
        objetoFormatado.mapaExtensoes.id_hierarquia1 = this.acaoMassiva.acao.camposGerais.hierarquia1.id;
      }
      if (this.acaoMassiva.acao.camposGerais.hierarquia2) {
        objetoFormatado.mapaExtensoes.id_hierarquia2 = this.acaoMassiva.acao.camposGerais.hierarquia2.id;
      }
      if (this.acaoMassiva.acao.camposGerais.hierarquia3) {
        objetoFormatado.mapaExtensoes.id_hierarquia3 = this.acaoMassiva.acao.camposGerais.hierarquia3.id;
      }
      if (this.acaoMassiva.acao.camposGerais.setoratividade) {
        objetoFormatado.mapaExtensoes.id_setoratividade = this.acaoMassiva.acao.camposGerais.setoratividade.id;
      }
      if (this.acaoMassiva.acao.camposGerais.grupomercadoria) {
        objetoFormatado.mapaExtensoes.id_grupomercadoria = this.acaoMassiva.acao.camposGerais.grupomercadoria.id;
      }
      if (this.acaoMassiva.acao.camposGerais.familia) {
        objetoFormatado.mapaExtensoes.id_familia = this.acaoMassiva.acao.camposGerais.familia.id;
      }
      if (this.acaoMassiva.acao.camposGerais.linhaproduto) {
        objetoFormatado.mapaExtensoes.id_linhaproduto = this.acaoMassiva.acao.camposGerais.linhaproduto.id;
      }
      if (this.acaoMassiva.acao.camposGerais.classe) {
        objetoFormatado.mapaExtensoes.id_classe = this.acaoMassiva.acao.camposGerais.classe.id;
      }
      if (this.acaoMassiva.acao.camposGerais.produto) {
        this.acaoMassiva.acao.camposGerais.produto.forEach((e) => {
          objetoFormatado.idProduto.push(e.id);
        });
      }
    },
    formataCamposDinamicos(objetoFormatado) {
      objetoFormatado.mapaCamposDinamicos = { ...this.acaoMassiva.acao.camposGerais };
    },
    formataFocoAcao(objetoFormatado) {
      const {
        cliente,
        holding,
        regional,
        extensaoCliente,
        clientePagador,
        fornecedorPagador,
      } = this.$refs.formFocoAcao.getAcao();

      if (holding && holding.id) {
        objetoFormatado.idHolding = holding.id;
      }
      if (cliente && cliente.id) {
        objetoFormatado.idCliente = cliente.id;
      }
      if (regional && regional.id) {
        objetoFormatado.idRegional = regional.id;
      }
      if (clientePagador && clientePagador.id) {
        objetoFormatado.idClientePagador = clientePagador.id;
      }
      if (extensaoCliente && extensaoCliente.id) {
        objetoFormatado.idExtensaoCliente = extensaoCliente.id;
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    validaSecaoDetalheAcao() {
      return this.$refs.detalheAcao.validaSecaoDetalheAcao();
    },
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracao.aplicaParametrizacao(this.getAcaoMetadado);
      this.metadadosCamposGerais = this.configuracao.aplicaParametrizacao(this.getAcaoMetadado);
      const arr2 = ['customergroup', 'canal', 'subcanal'];
      arr2.forEach((a) => {
        const indice = this.metadadosCamposGerais.cabecalho.indexOf(a);
        this.metadadosCamposGerais.cabecalho.splice(indice, 1);
      });
      delete this.metadadosCamposGerais.mapaEntidades.customergroup;
      delete this.metadadosCamposGerais.mapaEntidades.canal;
      delete this.metadadosCamposGerais.mapaEntidades.subcanal;

      this.configuracao
        .parametrizaCamposOpcionais(this.camposFormulario.padrao);

      if (this.isDetalheAcaoTemplate) {
        const campoValor = this.camposFormulario.padrao.filter((c) => c.labelCampo === 'valor')[0];
        campoValor.desAtributos = { desabilitado: true };
      }
    },
    preencherExtensoesCarteiras() {
      this.configuracao.extensoesCarteira.forEach((e) => {
        const extensao = this.acao[e.label];
        extensao.descricao = `${extensao.idExterno} - ${extensao.nomExtensao}`;
        this.listasExtensoesCarteira[e.label] = [extensao];
      });
    },
    validacaoCustomizada(isSolicitandoAprovacao) {
      if (!isSolicitandoAprovacao) {
        return true;
      }
      if (this.configuracao.uploadObrigatorioArquivo && this.qtdeArquivosAnexados === 0) {
        this.$toast(this.$t('message.upload_arquivo_obrigatorio_solicitacao'));
        return false;
      }
      return true;
    },
    validarDatas() {
      const dataInicial = moment(this.acaoMassiva.dtaInicio);
      const dataFinal = moment(this.acaoMassiva.dtaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    preencherValoresAutomaticos() {
      if (this.configuracao.indDescricaoAutomatica) {
        this.acaoMassiva.descricao = this.configuracao.descricaoAutomatica;
      }

      if (this.configuracao.indVigenciaAutomatica) {
        this.acaoMassiva.dtaInicio = this.moment().format('YYYY-MM-DD');
        this.acaoMassiva.dtaFim = this.acaoMassiva.dtaInicio;
      }
    },
    alteradoFoco(isFocoRegional) {
      if (this.visualizaSecaoRoi) {
        this.$refs.formRoi.setFocoRegional(isFocoRegional);
        if (this.acaoMassiva.divisao && (this.acaoMassiva.cliente || this.acaoMassiva.holding)) {
          this.$refs.formRoi.focoAlterado();
        } else {
          this.$refs.formRoi.setValoresZerados();
        }
      }
      this.emitirFocoAlterado();
      this.emitirCampoProdutoAlterado();
    },
    convertToMoney,
    convertToPercent,
    convertToNumberFormat,
    numberBetweenValues,
    cancela() {
      this.showModal = false;
    },
    ajustarValorAcao() {
      if (!verbaPercentual) {
        this.$emit('PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte',
          this.pegarMaiorValorDaMeta());
      }
    },
    pegarMaiorValorDaMeta() {
      return this.metas.map((m) => m.vlrBonificacao)
        .reduce((a, b) => Math.max(a, b));
    },
    salvar() {
      if (this.meta.vlrMetaDe) {
        this.meta.vlrMetaDe = Number(this.meta.vlrMetaDe);
      }
      if (this.meta.vlrMetaAte) {
        this.meta.vlrMetaAte = Number(this.meta.vlrMetaAte);
      }
      if (!this.$refs.formModal.validate() || this.intervaloEmConflito()
        || this.valoresNegativos()) return;
      if (!this.edicao) {
        this.acaoMassiva.acao.metas.push(this.meta);
      }
      this.showModal = false;
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
    intervaloEmConflito() {
      for (let i = 0; i < this.acaoMassiva.acao.metas.length; i += 1) {
        const intervalo = this.acaoMassiva.acao.metas[i];
        if (this.meta.index !== i
          && this.validarConflito(intervalo.vlrMetaDe, intervalo.vlrMetaAte)) {
          this.$toast(this.$t('errors.meta.intervalos.conflito'));
          return true;
        }
      }
      return false;
    },
    validarConflito(vlrMetaDe, vlrMetaAte) {
      if (!this.meta.vlrMetaAte) {
        return this.validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte);
      }
      return this.validarConflitoEntreValores(vlrMetaDe, vlrMetaAte);
    },
    validarConflitoEntreValores(vlrMetaDe, vlrMetaAte) {
      return this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
        || this.numberBetweenValues(this.meta.vlrMetaAte, vlrMetaDe, vlrMetaAte)
        || this.numberBetweenValues(vlrMetaAte, this.meta.vlrMetaDe,
          this.meta.vlrMetaAte)
        || this.numberBetweenValues(vlrMetaDe, this.meta.vlrMetaDe,
          this.meta.vlrMetaAte);
    },
    validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte) {
      return !vlrMetaAte
        || this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
        || this.meta.vlrMetaDe < vlrMetaDe;
    },
    valoresNegativos() {
      if (this.meta.vlrMetaDe < 0 || this.meta.vlrMetaAte < 0 || this.meta.vlrBonificacao < 0) {
        this.$toast(this.$t('errors.meta.negativa'));
        return true;
      }
      return false;
    },
    alterarValorAcao() {
      this.$emit('PlanejamentoAcaoFormApuracao__alterarValorAcao', this.formulario.valorAcao);
      this.acaoMassiva.formularioConta.periodos = [];
      if (this.acaoMassiva.acao.idAcao) {
        this.acaoMassiva.acao.alterouDados = true;
      }
    },
    montarFormularioCalculoAutomatico() {
      const parametros = {};

      parametros.template = this.configuracao.templateCalculo;
      parametros.templateCalculo = this.configuracao.configuracao.passo3.template;
      parametros.baseCalculo = this.acaoMassiva.baseCalculo.valor;
      parametros.calculoApuracao = this.acaoMassiva.calculoApuracao.valor;
      parametros.calculoBonificacao = this.acaoMassiva.calculoBonificacao.valor;
      parametros.formaBonificacao = this.acaoMassiva.acao.formaPagamento;

      parametros.idUnidadeMedida = this.formulario.unidadeMedida;
      parametros.descontos = ['DEVOLUCOES'];
      parametros.metas = [];
      if (this.metaIntervalo && this.acaoMassiva.acao.metas && this.acaoMassiva.acao.metas.length > 0) {
        this.acaoMassiva.acao.metas.forEach((meta) => {
          parametros.metas.push({
            metaDe: meta.vlrMetaDe,
            metaAte: meta.vlrMetaAte,
            bonificacao: meta.vlrBonificacao,
          });
        });
      } else {
        parametros.metas.push({
          metaDe: this.formulario.meta,
          metaAte: 0,
          bonificacao: this.acaoMassiva.valorBonificacao,
        });
      }

      if (this.getProdutoMetadado && this.getProdutoMetadado.cabecalho
        && this.getProdutoMetadado.cabecalho.length > 0) {
        parametros.idsExtensoesProduto = [];
        this.getProdutoMetadado.cabecalho.forEach((extensao) => {
          if (this.acaoMassiva.acao.camposGerais[extensao]) {
            if (Array.isArray(this.acaoMassiva.acao.camposGerais[extensao])) {
              this.acaoMassiva.acao.camposGerais[extensao].forEach((e) => {
                parametros.idsExtensoesProduto.push(e.id);
              });
            } else {
              parametros.idsExtensoesProduto.push(this.acaoMassiva.acao.camposGerais[extensao].id);
            }
          }
        });
      }
      if (this.acaoMassiva && this.acaoMassiva.holding) {
        parametros.idHolding = this.acaoMassiva.holding.id;
      }

      if (this.acaoMassiva && this.acaoMassiva.cliente) {
        parametros.idCliente = this.acaoMassiva.cliente.id;
      }

      if (this.acaoMassiva.acao.camposGerais.produto && this.acaoMassiva.acao.camposGerais.produto.length > 0) {
        parametros.idsProdutos = this.acaoMassiva.acao.camposGerais.produto.map((p) => p.id);
      }

      parametros.dtaFim = this.acaoMassiva.dtaFim;
      parametros.dtaInicio = this.acaoMassiva.dtaInicio;

      return parametros;
    },
    calcularValorAutomatico() {
      const parametros = this.montarFormularioCalculoAutomatico();
      this.planejamentoAcaoResource.buscarCalculoAutomatico(parametros)
        .then((res) => {
          this.acaoMassiva.acao.valor = res.data;
          this.formulario.valorAcaoCalculado = res.data;
          this.alterarValorAcao();
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarTiposVerbas() {
      const { listaIdVerbaInvestimento } = this.configuracao;

      const { idTipoVerba } = this.acaoMassiva.formularioConta;
      const param = idTipoVerba ? { idTipoVerba } : {};

      listaIdVerbaInvestimento.forEach((idOrcamentoVerbaInvestimento) => {
        this.orcamentoTipoVerbaResource
          .buscarPorIdVerbaInvestimento({ idOrcamentoVerbaInvestimento, ...param })
          .then(({ data }) => {
            if (data) {
              this.tiposVerbas.push(data);
              this.tiposVerbasPermitidas.push(data);
              this.ordenaTiposVerbas(this.tiposVerbas);
              this.ordenaTiposVerbas(this.tiposVerbasPermitidas);
              if (this.acaoMassiva.acao.formaPagamento === 'FIXO') {
                this.tiposVerbasPermitidas = this.tiposVerbas.filter((el) => el.indRateioDivisao || !el.indVerbaPercentual);
              }
              if (this.acaoMassiva.acao.formaPagamento === 'PERCENTUAL') {
                this.tiposVerbasPermitidas = this.tiposVerbas.filter((el) => el.indRateioDivisao || el.indVerbaPercentual);
              }
              if (this.acaoMassiva.formularioConta && data.id === this.acaoMassiva.formularioConta.idTipoVerba) {
                this.verbaSelecionada = data;
                this.setFlagMascaraPercent(this.verbaSelecionada.id);
                if (this.verbaSelecionada.indBloqueioTemp) {
                  obterCalendarioPorMnemonico({ mnemonico: 'BLOQUEIO_USO_VERBA' }, this.$resource)
                    .then((response) => {
                      this.eventoCalendario = response.body;
                      this.mostrarFraseVerbaBloqueada = this.acaoMassiva.formularioConta.idTipoVerba === this.verbaSelecionada.id
                        && this.eventoCalendario.dataInicio !== undefined;
                    }, (err) => {
                      this.$error(this, err);
                    });
                }
              }
            }
          });
      });
    },
    ordenaTiposVerbas(tiposVerbas) {
      tiposVerbas.sort((a, b) => {
        const fa = a.nome.toLowerCase(),
          fb = b.nome.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
    mostrarIconeVerbaBloqueada() {
      return undefined;
    },
    setFlagMascaraPercent(idTipoVerba) {
      if (this.tiposVerbas == null || this.tiposVerbas === '') {
        return;
      }
      this.mascaraPercent = this.tiposVerbas.filter((item) => item.id === idTipoVerba)[0].indVerbaPercentual;
    },
    buscarPeriodos() {
      const idDivisao = this.acaoMassiva.divisao.id;
      const dataInicio = this.acaoMassiva.dtaInicio;
      const dataFim = this.acaoMassiva.dtaFim;
      const { idTipoVerba } = this.acaoMassiva.formularioConta;

      this.planejamentoAcaoResource
        .buscarPeriodos({
          idDivisao, idTipoVerba, dataInicio, dataFim,
        })
        .then((res) => {
          this.periodosContas = [...res.data];
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    tipoVerbaSelecionado(idTipoVerba) {
      if (idTipoVerba) {
        if (this.acaoMassiva.acao.idAcao) {
          this.acaoMassiva.acao.alterouDados = true;
        }
        this.mascaraPercent = false;
        const rateioDivisao = this.tiposVerbasPermitidas.filter((verba) => verba.id === idTipoVerba)
          .map((verbaSelecionada) => verbaSelecionada.indRateioDivisao)[0];
        this.acaoMassiva.acao.indRateioDivisao = rateioDivisao;
        this.key += 1;
        this.mostrarFraseVerbaBloqueada = idTipoVerba === this.verbaSelecionada.id;
        this.setFlagMascaraPercent(idTipoVerba);
        this.acaoMassiva.formularioConta.periodos = [];
        this.buscarPeriodos();
      }
    },
    periodosSelecionados() {
      if (this.acaoMassiva.acao.idAcao) {
        this.acaoMassiva.acao.alterouDados = true;
      }
    },
    preencherInfoAcaoMassiva() {
      const parametros = {
        id_acao_previa: this.acaoMassiva.idAcaoPrevia,
      };
      buscarinfoAcaoTradePrevia(parametros, this.$resource).then((res) => {
        this.acaoMassiva.dtaInicio = res.data.dtaInicio;
        this.acaoMassiva.dtaFim = res.data.dtaFim;
        const idTipoAcao = res.data.idAcaoTipo;
        this.idAcao = res.data.idAcao;
        Promise.all([
          this.planejamentoAcaoListagemResource.buscarAcao({ idAcao: res.data.idAcao }),
          this.configuracaoResource.buscarConfigVigente({ idTipoAcao }),
        ])
          .then((resp) => {
            this.acaoEdicao = { ...resp[0].data };
            this.configuracao = new AcaoConfiguracao(resp[1].data);
            this.formFoco = true;
            this.acaoMassiva.divisao = resp[0].data.passoGeral.divisao;
            this.acaoMassiva.unidadeNegocio = resp[0].data.passoGeral.unidadeNegocio;
            this.unidadesNegocioDivisao = [resp[0].data.passoGeral.unidadeNegocio];
            this.aplicaParametrizacoes();
            this.preencherValoresAutomaticos();
            this.carregarTiposVerbas();
            this.emitirFocoAlterado();
          });
      }).catch((err) => {
        this.$toast(err.data.error);
      });
      return true;
    },
    limpaData() {
      this.valorInterno = null;
      this.emiteValorAlterado(null);
      setTimeout(() => this.alteraValorApresentavel());
    },
    bloqueioPorPerfil() {
      if (this.usuarioLogado.idPerfil !== 29) {
        const hoje = new Date();
        const dataLimite = new Date(hoje);
        dataLimite.setDate(hoje.getDate() - 120);
        const primeiroDiaMes = new Date(dataLimite.getFullYear(), dataLimite.getMonth(), 1);
        return primeiroDiaMes.toISOString().split('T')[0];
      }
      return null;
    },
    formatarData(data) {
      return data ? moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY') : '';
    },
    atualizarData(valor) {
      this.acaoMassiva.dtaInicio = valor
        ? moment(valor, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : null;
    },
  },
  mounted() {
    const { idAcao } = this.$route.params;
    if (idAcao !== null && idAcao !== undefined) {
      this.acaoMassiva.idAcaoPrevia = idAcao;
      this.preencherInfoAcaoMassiva(idAcao);
    }
    this.buscarDivisoes();
    this.buscarDivisoesUsuario();
    this.parametrosAplicados = true;
    window.scrollTo(0, 0);
    if (idAcao === null || idAcao === undefined) {
      this.buscarTipoAcao();
    }
  },
};
</script>
